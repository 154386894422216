<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row type="flex"
              justify="space-between"
              style="margin-bottom: 30px">
        <el-button type="primary"
                   @click="goAddPuduct">添加产品</el-button>
        <el-input placeholder="请输入内容"
                  v-model="queryInfo.keyword"
                  clearable
                  @clear="getProductList"
                  style="width:400px">
          <el-button slot="append"
                     icon="el-icon-search"
                     class="search-btn"
                     @click="getProductList"></el-button>
        </el-input>
      </el-row>

      <!-- 产品列表 -->
      <el-table :data="productListData"
                stripe
                style="width: 100%">
        <el-table-column prop="sort"
                         label="排序"
                         width="100">
          <template slot-scope="scope">
            <el-input size="mini"
                      v-model="scope.row.sort"
                      @blur="sortChange(scope.row)"
                      style="width: 60px;"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="产品名称"></el-table-column>
        <el-table-column label="产品图片">
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 80px"
                      :src="globalUrl + scope.row.logo"></el-image>
          </template>
        </el-table-column>

        <el-table-column label="是否显示"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="userStateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="catname"
                         label="产品分类"></el-table-column>
        <el-table-column prop="update_time"
                         label="更新时间"></el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="editById(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="removeById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.current_page"
                     :page-sizes="[5, 10, 15, 20]"
                     :page-size="queryInfo.per_page"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total"
                     background>
      </el-pagination>
    </el-card>
  </div>

</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        // 搜索关键字
        keyword: '',
        // 当前页码数
        current_page: 1,
        // 每页显示数
        per_page: 5
      },
      productListData: [],
      total: 0,
      isShowaddGoods: false,
      // 全局图片上传路径
      globalUrl: this.globalUrlapi
    }
  },

  created () {
    this.getProductList()
  },

  methods: {
    // 加载列表数据
    async getProductList () {
      const { data: res } = await this.$http.get('goods', { params: this.queryInfo })
      if (res.code !== 200 && res.msg !== 'success') {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.productListData = res.data.data
      this.total = res.data.total
    },

    // 分页组件
    handleSizeChange (newSize) {
      this.queryInfo.per_page = newSize
      this.getProductList()
    },

    handleCurrentChange (newPage) {
      this.queryInfo.current_page = newPage
      this.getProductList()
    },

    // 改变状态
    async userStateChange (userinfo) {
      const { data: res } = await this.$http.put(`goods/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getProductList()
    },

    // 排序
    async sortChange (userinfo) {
      // console.log(userinfo);
      const { data: res } = await this.$http.put(`goods/${userinfo.id}?sort=${userinfo.sort}`)
      if (res.code !== 200) {
        userinfo.sort = this.userinfo.sort
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getProductList()
    },

    // 添加产品
    goAddPuduct () {
      this.$router.push('/product/add')
    },

    // 修改产品
    editById (id) {
      this.$router.push(
        { path: '/product/edit/' + id }
      )
    },

    // 删除功能
    async removeById (id) {
      const confirmResult = await this.$confirm('此操作将永久删除该产品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }

      const { data: res } = await this.$http.delete('goods/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('产品删除失败！请重试...')
      }
      this.$message.success('产品删除失败！')
      this.getProductList()
    }
  }
}
</script>

<style>
</style>
