<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改产品信息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-form :model="editForm"
             :rules="editFormRules"
             ref="editFormRef"
             label-position="top">
      <!-- tab栏区域 -->
      <el-tabs type="border-card">
        <el-tab-pane label="基本信息">
          <!-- 输入区域 -->
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="产品名称："
                            prop="name">
                <el-input v-model="editForm.name"></el-input>
              </el-form-item>
              <el-form-item label="应用描述："
                            prop="protype">
                <el-input v-model="editForm.protype"></el-input>
              </el-form-item>
              <el-form-item label="产品认证：">
                <el-input v-model="editForm.auth"></el-input>
              </el-form-item>
              <el-form-item label="点间距："
                            prop="pixel">
                <el-input v-model="editForm.pixel"></el-input>
              </el-form-item>
              <el-form-item label="质保服务：">
                <el-input v-model="editForm.warranty"></el-input>
              </el-form-item>
              <!-- 分类选择 -->
              <el-form-item label="上级分类："
                            prop="cate_id">
                <el-cascader v-model="selectedKeys"
                             :options="cateList"
                             :props="{ expandTrigger: 'hover', value: 'id', label: 'catname', children: 'children', checkStrictly: true }"
                             :placeholder="placeholder"
                             clearable
                             @change="handleCheckChange"
                             style="width:100%"></el-cascader>
              </el-form-item>

              <el-form-item label="图片上传：*只能上传jpg/png文件，且不超过500kb"
                            prop="logo">
                <el-upload :data="imgtype"
                           :action="uploadURL"
                           :headers=" headerObj"
                           :limit="1"
                           :on-preview="handlePreview"
                           :before-upload="beforeUpload"
                           :on-success="handleSuccess"
                           :file-list="fileList"
                           list-type="picture">
                  <el-button size="small"
                             type="primary">点击上传</el-button>
                </el-upload>
                <el-dialog :visible.sync="imgDialogVisible"
                           width="50%"
                           append-to-body>
                  <img width="100%"
                       :src="dialogImageUrl"
                       alt="">
                </el-dialog>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="产品特点1："
                            prop="feature1">
                <el-input v-model="editForm.feature1"></el-input>
              </el-form-item>
              <el-form-item label="产品特点2："
                            prop="feature2">
                <el-input v-model="editForm.feature2"></el-input>
              </el-form-item>
              <el-form-item label="产品特点3："
                            prop="feature3">
                <el-input v-model="editForm.feature3"></el-input>
              </el-form-item>
              <el-form-item label="产品特点4："
                            prop="feature4">
                <el-input v-model="editForm.feature4"></el-input>
              </el-form-item>
              <el-form-item label="产品特点5："
                            prop="feature5">
                <el-input v-model="editForm.feature5"></el-input>
              </el-form-item>
              <el-form-item label="产品特点6：">
                <el-input v-model="editForm.feature6"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="产品详情">
          <!-- 富文本编辑器组件 -->
          <el-form-item label="详情内容：">
            <TEditor v-model="editForm.content"
                     :baseUrl="baseUrl"></TEditor>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="upEditForm">提交</el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>

  </div>
</template>

<script>
export default {
  data () {
    return {
      // 富文本上传路径
      baseUrl: '/logo?type=product',
      // 添加表单
      editForm: {
        name: '',
        protype: '',
        auth: '',
        pixel: '',
        cate_id: '',
        warranty: '',
        logo: '',
        feature1: '',
        feature2: '',
        feature3: '',
        feature4: '',
        feature5: '',
        feature6: '',
        content: ''
      },
      // 表单验证规则
      editFormRules: {
        name: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        pixel: [
          { required: true, message: '请输入相关点间距', trigger: 'blur' }
        ],
        protype: [
          { required: true, message: '请输入产品应用描述', trigger: 'blur' }
        ],
        feature1: [
          { required: true, message: '请输入产品特点', trigger: 'blur' }
        ],
        feature2: [
          { required: true, message: '请输入产品特点', trigger: 'blur' }
        ],
        feature3: [
          { required: true, message: '请输入产品特点', trigger: 'blur' }
        ],
        feature4: [
          { required: true, message: '请输入产品特点', trigger: 'blur' }
        ],
        feature5: [
          { required: true, message: '请输入产品特点', trigger: 'blur' }
        ],
        cate_id: [
          { required: true, message: '请选择产品分类', trigger: 'change' }
        ],
        logo: [
          { required: true, message: '请选择上传图片', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入产品详情', trigger: 'blur' }
        ]
      },
      // 分类列表控件
      // 选中的父级数组
      selectedKeys: [],

      // 级联选择器占位符
      placeholder: '请选择产品分类',

      cateList: [],

      // 图片上传地址
      uploadURL: this.globalUrlapi + '/logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'product'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  methods: {
    // 获取数据
    async getProductList (id) {
      const { data: res } = await this.$http.get('goods/' + id + '/edit')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.editForm = res.data.goods
      this.cateList = res.data.category
      this.fileList.push({ name: res.data.goods.logo, url: this.globalUrlapi + res.data.goods.logo })
      this.selectedKeys = res.data.goods.cate_id
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.editForm.logo = response.data
    },

    // 分类选择器触发函数
    handleCheckChange () {
      // 如果 this.selectedKeys 数组中的 length 大于0，则选中父级分类
      // 反之，就说明没有选中任何父级分类
      if (this.selectedKeys.length > 0) {
        this.editForm.cate_id = this.selectedKeys[this.selectedKeys.length - 1]
      } else {
        this.editForm.cate_id = 0
      }
    },

    // 发起表单上传
    upEditForm () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        // 如果正确发起添加请求
        const { data: res } = await this.$http.put('goods/' + this.editForm.id, {
          name: this.editForm.name,
          protype: this.editForm.protype,
          auth: this.editForm.auth,
          pixel: this.editForm.pixel,
          cate_id: this.editForm.cate_id,
          warranty: this.editForm.warranty,
          logo: this.editForm.logo,
          feature1: this.editForm.feature1,
          feature2: this.editForm.feature2,
          feature3: this.editForm.feature3,
          feature4: this.editForm.feature4,
          feature5: this.editForm.feature5,
          feature6: this.editForm.feature6,
          content: this.editForm.content
        })
        if (res.code !== 200) {
          this.$message.error('网络延时，请重新刷新网页重试...')
        }
        this.$message.success('产品修改成功！')
        // 跳转
        this.$router.push('/product')
      })
    },

    goBack () {
      this.$router.push('/product')
    }
  },

  created () {
    this.getProductList(this.$route.params.id)
  }
}
</script>

<style>
</style>
