<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>案例管理</el-breadcrumb-item>
      <el-breadcrumb-item>案例列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <el-button type="primary"
                 class="addbtn"
                 @click="showAddDialog">添加案例</el-button>
      <!-- 新闻列表 -->
      <el-table :data="showList"
                stripe
                style="width: 100%">
        <el-table-column type="index"
                         label="#"
                         width="180"></el-table-column>
        <el-table-column prop="name"
                         label="案例名称"></el-table-column>

        <el-table-column label="首页展示"
                         prop="is_show">
          <template slot-scope="scope"
                    v-if="scope.row.status === 1 ">
            <el-switch v-model="scope.row.is_show"
                       :active-value="1"
                       :inactive-value="0"
                       @change="isShowChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="markets.name"
                         label="案例类型"></el-table-column>

        <el-table-column label="是否显示"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="update_time"
                         label="更新时间"></el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加编辑表单 -->
    <el-dialog :title="titleMap[dialogTitle]"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 表单区域 -->
      <el-form :model="dateForm"
               :rules="dateFormRules"
               ref="dateFormRef"
               label-position="top">
        <el-form-item label="案例名称："
                      prop="name">
          <el-input v-model="dateForm.name"></el-input>
        </el-form-item>
        <el-form-item label="点间距："
                      prop="pixel">
          <el-input v-model="dateForm.pixel"></el-input>
        </el-form-item>
        <el-form-item label="案例所在地："
                      prop="location">
          <el-input v-model="dateForm.location"></el-input>
        </el-form-item>

        <el-form-item label="案例类型："
                      prop="cate_id">
          <el-select v-model="dateForm.cate_id"
                     placeholder="请选择案例类型">
            <el-option v-for="item in cateList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 图片上传 -->
        <el-form-item label="图片上传："
                      prop="logo">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers=" headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          <el-dialog :visible.sync="imgDialogVisible"
                     width="50%"
                     append-to-body>
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      showList: [],
      titleMap: {
        addData: '添加案例',
        updateData: '修改案例信息'
      },
      dialogTitle: '',
      cateList: [],

      // 表单
      dateForm: {
        name: '',
        pixel: '',
        location: '',
        cate_id: '',
        logo: ''
      },
      // 表单验证规则
      dateFormRules: {
        name: [
          { required: true, message: '请输入案例名称', trigger: 'blur' }
        ],
        pixel: [
          { required: true, message: '请输入案例简述', trigger: 'blur' }
        ],
        location: [
          { required: true, message: '请输入案例所在地', trigger: 'blur' }
        ],
        cate_id: [
          { required: true, message: '请选择案例类型', trigger: 'change' }
        ],
        logo: [
          { required: true, message: '请选择上传案例图片', trigger: 'blur' }
        ]
      },

      dialogVisible: false,

      // 图片上传地址
      uploadURL: this.globalUrlapi + '/logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'project'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  methods: {
    async getShowList () {
      const { data: res } = await this.$http.get('project')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`project/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getShowList()
    },

    // 首页展示
    async isShowChange (userinfo) {
      const { data: res } = await this.$http.put(`project/${userinfo.id}?is_show=${userinfo.is_show}`)
      if (res.code !== 200) {
        userinfo.is_show = !userinfo.is_show
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getShowList()
    },

    // 删除管理员信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这条案例吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }

      const { data: res } = await this.$http.delete('project/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功')
      this.getShowList()
    },

    // 添加按钮
    showAddDialog () {
      this.dialogVisible = true
      this.dialogTitle = 'addData'
      this.getCateList()
    },

    // 展示修改对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('project/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrlapi + res.data.logo })
      this.dialogTitle = 'updateData'
      this.dialogVisible = true
      this.getCateList()
    },

    // 获取分类列表信息
    async getCateList () {
      const { data: res } = await this.$http.get('project/create')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请重新刷新网页重试...')
      }
      this.cateList = res.data
    },

    // 关闭 Dialog，清除历史数据
    addDialogClose () {
      this.$refs.dateFormRef.resetFields()
      this.fileList = []
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    upDate () {
      // 添加请求
      if (this.dialogTitle === 'addData') {
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          // 如果正确发起添加请求
          const { data: res } = await this.$http.post('project', this.dateForm)
          if (res.code !== 200) {
            this.$message.error('网络延时，请重新刷新网页重试...')
          }
          this.$message.success('添加成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getShowList()
        })
      } else {
        // 修改请求
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.put('project/' + this.dateForm.id, {
            name: this.dateForm.name,
            pixel: this.dateForm.pixel,
            location: this.dateForm.location,
            cate_id: this.dateForm.cate_id,
            logo: this.dateForm.logo
          })
          if (res.code !== 200) {
            return this.$message.error('修改管理员信息失败，请重试。')
          }
          this.$message.success('修改成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getShowList()
        })
      }
    }
  },

  created () {
    this.getShowList()
  }
}
</script>

<style scoped>
</style>
